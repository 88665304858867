header {
  /* box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.14); */
  padding: 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
}

/* style.css */
.scrolled-header {
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.14);
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__list {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar__item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.navbar__item-trans span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ff0060;
  font-weight: 600;
  color: #ff0060;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.navbar__item-trans span:hover {
  background: #ff0060;
  color: #fff;
}

.navbar__translate {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  left: -7px;
  bottom: -60px;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
}

.navbar__translate-item {
  cursor: pointer;
}

.navbar__item select {
  width: 100%;
  max-width: 100%;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  border-radius: 3px;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.navbar__item select::-ms-expand {
  display: none;
}

.navbar__item select option {
  font-weight: 600;
  border: none;
}

.navbar__link {
  font-size: 16px;
  font-weight: 500;
  color: #455a65;
  transition: all 0.2s ease;
}

/* .navbar__link:hover {
  transform: scale(1.05);
} */

.navbar__link-icon {
  display: none;
  font-size: 20px;
}

.navauth,
.burger {
  cursor: pointer;
  border: none;
  background: #084cdf;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.burger {
  display: none;
  background: #ff0060;
}

.navauth:hover {
  background: #0d45a5;
}

.navauth:active {
  background: #1c4488;
}

.nav-create {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: none;
  background: #ff0060;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.nav-create:hover,
.burger:hover {
  background: #dd0055;
}

.nav-create:active,
.burger:active {
  background: #b30145;
}

.nav-create-text2 {
  display: none;
}

.nav-add {
  font-size: 16px;
}

.nav-add path {
  stroke: #fff;
}

.navbar-mobile {
  /* display: none; */
  transform: translate(-100%);
  transition: all 0.5s ease;
  position: absolute;
  border-top: 5px solid #ff0060;
  left: 0;
  top: 78px;
  height: 100vh;
  width: 80%;
  background: #fff;
  padding: 40px 0;
  box-shadow: 4px 0px 11px 0px rgba(34, 60, 80, 0.2);
  /* display: none; */
}

.show-nav {
  transform: translate(0);
}

.navbar-mobile .navbar__list {
  flex-direction: column;
}

.navbar-mobile .navauth {
  padding: 15px 45px;
}

.navauth-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar__link-text {
    display: none;
  }

  .navbar__link-icon {
    display: block;
  }

  .navauth {
    padding: 8px 14px;
  }

  .burger {
    padding: 11px 15px;
  }

  .navbar-desktop .nav-create {
    padding: 10px 14px;
    /* border-radius: 7px; */
  }

  .nav-create-text1 {
    display: none;
  }

  .nav-create-text2 {
    display: block;
  }
}

@media screen and (max-width: 598px) {
  .navbar-desktop {
    display: none;
  }

  .navbar-mobile {
    display: block;
  }

  .navauth-btn,
  .burger {
    display: block;
  }
}
