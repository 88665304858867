footer {
  background-color: #3a4950;
  padding: 20px 0;
}

footer p {
  text-align: center;
  color: #ffffff;
  font-family: "Nunito";
}

a {
  text-decoration: none;
  color: #ffffff;
}
