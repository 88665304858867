.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%);
}

.loader img {
  width: 120px;
  height: 120px;
  border-radius:12px;
  object-fit: cover;
  animation: myAnim 1.5s ease 0s infinite normal forwards;
  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  -ms-border-radius:12px;
  -o-border-radius:12px;
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

.loader-overlay {
  width: 100%;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(4px) saturate(200%);
  -webkit-backdrop-filter: blur(4px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.32);
}
