.hero {
  padding: 40px 0;
}

.qr__container {
  /* width: 100%; */
  max-width: 1260px;
  padding: 0 30px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
}

.hero__container {
  display: flex;
  flex-direction: column;
}

.hero__title {
  font-size: 26px;
  color: #616568;
  margin-bottom: 20px;
}

.hero__content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.hero__form {
  width: 50%;
  padding: 10px;
  color: #616568;
}

.hero__form label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.hero__form input {
  display: block;
  /* width: 100%; */
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.hero__form-contact div,
.hero__form-company div,
.hero__form-socials div {
  display: grid;
  grid: auto / auto auto;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 10px 20px;
}

.hero__submit {
  cursor: pointer;
  border: none;
  background: #084cdf;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.hero__submit:hover {
  background: #0d45a5;
}

.hero__submit:active {
  background: #1c4488;
}

.hero__form-image {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  margin-bottom: 20px;
}

.hero__form-image:hover {
  background: #eee;
  border-color: #111;
}

.hero__form-image:hover .hero__form-image span {
  color: #222;
}

.hero__form-image span {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
  margin-bottom: 20px;
}

.hero__form-img-div {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

.hero__form-img-div input {
  border: none;
  text-align: center;
  background: transparent;
}

.img__btn {
  font-size: 12px;
  margin-right: 10px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.img__btn:hover {
  background: #0d45a5;
}

.hero__form-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .image__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.432);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
} */

.home__screen {
  margin-bottom: 60px;
  width: 35%;
}

.home__fullsrceen {
  background-color: #455a64;
  width: 100%;
}

.home__screen-tablet .home__fullsrceen {
  padding-top: 60px;
}

.home__screen-head {
  /* padding-top: 60px; */
  max-width: 480px;
  margin: auto;
  padding: auto;
  box-shadow: 0 -5px 40px 7px rgba(0, 0, 0, 0.08);
}

.home__header {
  padding-top: 60px;
  background-color: #455a64;
  color: #fff;
}

.home__avatar {
  margin: auto;
  padding: auto;
  height: 95px;
  width: 95px;
  border-radius: 100%;
}

.home__avatar img {
  width: 95px;
  height: 95px;
  border: none;
  border-radius: 100%;
  object-fit: cover;
}

.home__name {
  padding: 20px 0 15px 0;
  color: #fff;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}

.home__job {
  color: #fff;
  opacity: 0.75;
}

.home__call a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #82848f;
  padding: 15px 0;
  color: #fff;
  text-decoration: none;
}

.home__body {
  max-width: 480px;
  margin: auto;
  padding: 15px 0;
  box-shadow: 0 -5px 40px 7px rgba(0, 0, 0, 0.08);
  text-align: left;
}

.home__body ul {
  list-style-type: none;
}

.home__body li {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 40px;
  color: #82848f;
}

.home__item {
  padding: 0 0 0 10px;
}
.home__border-btm {
  margin-top: 10px;
  width: 85%;
}

.home__body h6 {
  color: #474747;
  font-size: 16px;
}

.home__body p {
  font-size: 14px;
}

.home__screen-tablet {
  width: 100%;
  display: none;
}

.qrcode-card {
  align-self: flex-start;
  padding: 20px;
  background: rgb(255, 247, 247);
  border: none;
  border-radius: 10px;
  box-shadow: 0 -5px 40px 7px rgba(0, 0, 0, 0.144);
}


@media screen and (max-width: 960px) {
  .hero__content {
    flex-direction: column;
  }

  .hero__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .hero__submit {
    align-self: center;
    margin-top: 30px;
  }

  .home__screen {
    display: none;
  }

  .home__screen-tablet {
    display: block;
  }

  .qrcode-card {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 530px) {
  .hero__form-contact div,
  .hero__form-company div,
  .hero__form-socials div {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 5px;
  }

  .qrcode-card {
    align-self: center;
  } 
  
}

@media screen and (max-width: 484px) {
  .home__screen-tablet .home__fullsrceen {
    padding: 0;
  }
}
@media screen and (max-width:445px) {
  .img__btn {
    margin-right: 0px;
    font-size: 10px;
    border: none;
    background: #084cdf;
    padding: 3 10px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
}
@media screen and (max-width:416px) {
  .img__btn {
    margin-right: -20px;
    border: none;
    background: #084cdf;
    padding: 7px 9px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
}
@media screen and (max-width:375px) {
  .img__btn {
    margin-right: -45px;
    padding: 8px 8px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
}
@media screen and (max-width:320px) {
  .img__btn {
    margin-right: -55px;
    padding: 6px 6px;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
}
