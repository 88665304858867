* {
  scroll-behavior: smooth;
}

.about {
  padding: 40px 0;
}

.about__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about__info {
  width: 400px;
}

.about__title {
  font-size: 50px;
  font-family: "Nunito Bold";
  font-weight: 700;
  color: #444;
  line-height: 1.2;
  margin-bottom: 15px;
}

.about__description {
  color: #333;
  font-size: 17px;
  font-family: "Nunito";
  line-height: 1.8;
  margin-bottom: 30px;
}

.about-createBtn {
  cursor: pointer;
  border: none;
  background: #ff0060;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.about-createBtn:hover {
  background: #dd0055;
}

.about-createBtn:active {
  background: #b30145;
}

/* WORK SECTION */
.work {
  background: #f9f9f9;
  padding: 65px 0;
  text-align: center;
}

.work__title,
.pricing__title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: #333;
  font-family: "Nunito";
  margin-bottom: 50px;
}

.work__list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.work__item {
  cursor: default;
  width: 320px;
  padding: 20px 15px 50px;
  font-family: "Nunito";
  /* border: 1px solid #ccc; */
  transition: all 0.5s ease;
}

.work__item:hover {
  /* border-bottom-right-radius: 100px;
  border-top-left-radius: 100px; */
  box-shadow: 4px 4px 20px -5px rgba(34, 60, 80, 0.2);
}

.work__item-img {
  margin-bottom: 35px;
}

.work__item-title {
  line-height: 26px;
  font-size: 22px;
  font-weight: 500;
  color: #333;
  margin-bottom: 30px;
}

.work__item-description {
  font-size: 16px;
  line-height: 27px;
  color: #808080;
}

#pricing {
  padding: 80px 0;
  font-family: "Nunito";
}

.pricing__title {
  text-align: center;
}

.pricing__list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
}

.pricing__item {
  width: 320px;
  border: none;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 19px -6px rgba(34, 60, 80, 0.37);
}

.pricing__item-title {
  width: 100%;
  padding: 15px 0;
  background-color: #dd0055;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Nunito";
}

.pricing__item-content {
  padding: 40px 20px 40px;
}

.pricing__price {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: #333;
  font-family: "Nunito";
  margin-bottom: 40px;
}

.pricing__price span {
  font-size: 18px;
}

.pricing__btn {
  text-align: center;
  cursor: pointer;
  border: 1px solid #ff0060;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 14px;
  color: #ff0060;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  margin-bottom: 20px;
}

.pricing__btn:hover {
  background: #ff0060;
  border-color: transparent;
  color: #fff;
}

.pricing__btn:active {
  background: #b30145;
}

@media screen and (max-width: 1100px) {
  .about__container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 930px) {
  .about {
    padding: 30px 0;
  }

  .about__info {
    width: 330px;
  }

  .about__title {
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 832px) {
  .about__container {
    flex-direction: column;
  }

  .about__info {
    width: 70%;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 602px) {
  .about__info {
    width: 90%;
  }

  .about__banner {
    width: 400px;
    height: auto;
  }
}

@media screen and (max-width: 482px) {
  .about__info {
    width: 100%;
  }

  .about__title {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .about__description {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .about__banner {
    width: 340px;
  }
}
